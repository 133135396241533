var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"ml-4",staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.cardTitle))])])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[(_vm.formModel)?_c('v-text-field',{attrs:{"label":_vm.$t('FORMS.name'),"rules":_vm.nameRules,"id":_vm.dynamicID,"error-messages":_vm.messages['name'],"disabled":""},on:{"keyup":function($event){_vm.messages['name'] = ''}},model:{value:(_vm.formModel.name),callback:function ($$v) {_vm.$set(_vm.formModel, "name", $$v)},expression:"formModel.name"}}):_vm._e(),(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .title
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "title", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .title\n                "}}):_vm._e(),(_vm.formModel && _vm.formModel.custom_fields)?_c('CustomFieldComponent',{attrs:{"config":{
                  type: 'text',
                  label: 'FORM_INPUT_NAMES.gtm',
                },"customFieldName":"gtm","value":_vm.formModel.custom_fields.gtm},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.formModel && _vm.formModel.custom_fields)?_c('CustomFieldComponent',{attrs:{"config":{
                      type: 'text',
                      label: 'FORM_INPUT_NAMES.recaptcha_site_key',
                    },"customFieldName":"recaptcha_site_key","value":_vm.formModel.custom_fields.recaptcha_site_key},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.formModel && _vm.formModel.custom_fields)?_c('CustomFieldComponent',{attrs:{"config":{
                      type: 'text',
                      label: 'FORM_INPUT_NAMES.recaptcha_site_secret',
                    },"customFieldName":"recaptcha_site_secret","value":_vm.formModel.custom_fields.recaptcha_site_secret},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[(_vm.formModel && _vm.formModel.custom_fields)?_c('v-autocomplete',{attrs:{"items":_vm.locales,"chips":"","multiple":"","label":_vm.$t('FORMS.select_site_language_order')},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('draggable',_vm._b({attrs:{"id":data.index,"list":_vm.formModel.custom_fields.selectedLanguages,"move":_vm.move},on:{"change":_vm.change}},'draggable',_vm.dragOptionsChips,false),[_c('v-chip',{key:data.item,attrs:{"draggable":"","close":"","color":"bg-gray-100"},on:{"click:close":function($event){return _vm.deleteChip(
                          data.item,
                          _vm.formModel.custom_fields.selectedLanguages
                        )},"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();}},model:{value:(
                        _vm.formModel.custom_fields.selectedLanguages[data.index]
                      ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.selectedLanguages, data.index, $$v)},expression:"\n                        formModel.custom_fields.selectedLanguages[data.index]\n                      "}},[_vm._v(" "+_vm._s(data.item)+" ")])],1)]}}],null,false,1053648336),model:{value:(_vm.formModel.custom_fields.selectedLanguages),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields, "selectedLanguages", $$v)},expression:"formModel.custom_fields.selectedLanguages"}}):_vm._e(),_c('draggable',_vm._b({model:{value:(_vm.formModel.custom_fields.selectedLanguages),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields, "selectedLanguages", $$v)},expression:"formModel.custom_fields.selectedLanguages"}},'draggable',_vm.dragOptionsChips,false)),_vm._v(" "+_vm._s(_vm.$t("FORM_INPUT_NAMES.select_public_languages"))+" "),(_vm.formModel && _vm.formModel.custom_fields)?_c('CustomFieldComponent',{attrs:{"config":{
                  type: 'multi_checkbox',
                  label: 'FORM_INPUT_NAMES.select_public_languages',
                  items: _vm.publicLocales,
                },"customFieldName":"publicLanguages","value":_vm.formModel.custom_fields.publicLanguages || []},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_vm._v(" SMTP: ")]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.formModel && _vm.formModel.custom_fields)?_c('CustomFieldComponent',{attrs:{"config":{
                  type: 'text',
                  label: 'FORM_INPUT_NAMES.sender_name',
                },"customFieldName":"name","value":_vm.formModel.custom_fields.name},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.formModel && _vm.formModel.custom_fields)?_c('CustomFieldComponent',{attrs:{"config":{
                  type: 'text',
                  label: 'FORM_INPUT_NAMES.sender_email',
                },"customFieldName":"email","value":_vm.formModel.custom_fields.email},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.formModel && _vm.formModel.custom_fields)?_c('CustomFieldComponent',{attrs:{"customFieldName":"favicon","value":_vm.formModel.custom_fields.favicon || '',"config":{
                  type: 'media_selector',
                  maxFiles: 1,
                  selectButtonText: 'FORM_INPUT_NAMES.select_favicon',
                  initialColumnClass:
                    'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12',
                }},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.formModel && _vm.formModel.custom_fields)?_c('CustomFieldComponent',{attrs:{"customFieldName":"logo","value":_vm.formModel.custom_fields.logo || '',"config":{
                  type: 'media_selector',
                  maxFiles: 1,
                  selectButtonText: 'FORM_INPUT_NAMES.select_logo',
                  initialColumnClass:
                    'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12',
                }},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider'),_c('h3',[_vm._v(" "+_vm._s(_vm.$t("FORMS.company_settings"))+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":_vm.$t('FORM_INPUT_NAMES.company_name')},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .companyName
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "companyName", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .companyName\n                "}}):_vm._e(),(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":_vm.$t('FORM_INPUT_NAMES.company_tax_number')},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .companyTaxNumber
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "companyTaxNumber", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .companyTaxNumber\n                "}}):_vm._e(),(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":_vm.$t('FORM_INPUT_NAMES.company_registration_number')},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .companyRegistrationNumber
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "companyRegistrationNumber", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .companyRegistrationNumber\n                "}}):_vm._e(),(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":_vm.$t('FORM_INPUT_NAMES.company_bank_account_number')},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .companyBankAccountNumber
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "companyBankAccountNumber", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .companyBankAccountNumber\n                "}}):_vm._e(),(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":_vm.$t('FORM_INPUT_NAMES.company_bank_iban')},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .companyBankIban
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "companyBankIban", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .companyBankIban\n                "}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":_vm.$t('FORM_INPUT_NAMES.company_address')},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .companyAddress
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "companyAddress", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .companyAddress\n                "}}):_vm._e(),(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":_vm.$t('FORM_INPUT_NAMES.company_phone')},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .companyPhone
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "companyPhone", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .companyPhone\n                "}}):_vm._e(),(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":_vm.$t('FORM_INPUT_NAMES.company_fax')},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .companyFax
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "companyFax", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .companyFax\n                "}}):_vm._e(),(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":_vm.$t('FORM_INPUT_NAMES.company_bank_swift')},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .companyBankSwift
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "companyBankSwift", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .companyBankSwift\n                "}}):_vm._e(),(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":_vm.$t('FORM_INPUT_NAMES.company_email')},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .companyEmail
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "companyEmail", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .companyEmail\n                "}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":"Facebook"},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .facebook
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "facebook", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .facebook\n                "}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":"YouTube"},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .youtube
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "youtube", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .youtube\n                "}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('v-text-field',{attrs:{"label":"Instagram"},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .instagram
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "instagram", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .instagram\n                "}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("FORM_INPUT_NAMES.company_slogen"))+" "),(
                  _vm.formModel &&
                  _vm.formModel.custom_fields &&
                  _vm.formModel.custom_fields.translations &&
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                )?_c('ckeditor',{attrs:{"config":_vm.editorConfig},model:{value:(
                  _vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    .companySlogen
                ),callback:function ($$v) {_vm.$set(_vm.formModel.custom_fields.translations[_vm.selectedLocale.lang]
                    , "companySlogen", $$v)},expression:"\n                  formModel.custom_fields.translations[selectedLocale.lang]\n                    .companySlogen\n                "}}):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleCloseModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.cancel"))+" ")]),(
          (_vm.permissionCan('create') && !_vm.formModel.id) ||
          _vm.permissionCan('update')
        )?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSaveModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }